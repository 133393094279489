import React from 'react'
import styled from 'styled-components'
import {responsiveTitle2Bold} from '../components/typography.module.css'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockContent from '../components/block-content'
import Fade from 'react-reveal/Fade'
import {FiMapPin} from 'react-icons/fi'
import RenderInBrowser from 'react-render-in-browser'

const Wrapper = styled.section`
  border-top: 1px solid white;
  padding: 2em 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-template-areas: 
    "image image text text"
    "image image text text";
  height: auto;
  >div:nth-child(2){  
    grid-area: text;
    background-color:var(--color-dark-gray);
    padding: 1rem;
  }
    >div:nth-child(2) ul{
      padding-left: 1em;
    }
  >div:nth-child(2) ul li{  
    list-style: disc; 
  }
  >div:nth-child(1){  
    grid-area: image;
    display:flex;
    align-items:center;
  }
  span{
    margin-right:0.4rem;
  }
  .contractor{
    font-size:0.7rem;

  }
  .location{
    text-align:left;
    margin: 0;
    padding: 0 0 0.2rem;
    font-size:var(--font-micro-small);
    @media (max-width: 1070px){
      font-size:0.9rem;
    }
    @media (max-width: 900px){
      font-size:0.8rem;
    }
  }
  :nth-child(even){
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 
    "text text image image "
    "text text image image ";
  }
  @media (max-width:600px) {
    height: auto;
    grid-template-columns: 1fr;
    grid-template-areas: 
    "image"
    "text";
    grid-template-rows: auto auto ;
    grid-row-gap: 2em;
    grid-auto-flow:row-dense;
    :nth-child(even){
      grid-template-columns: 1fr;
      align-items: start;
      grid-template-areas: 
      "image"      
      "text";
    }
  }
  `
const Image = styled.img`
  width: 100%;
`
function Article (props) {
  const {title, _rawBody, mainImage, location, contractor} = props
  return (
    <>
      <RenderInBrowser except safari ie>
        <Wrapper>
          <Fade>
            <Image
              src={imageUrlFor(buildImageObj(mainImage))
                .width(600)
                .height(350)
                .fit('crop')
                .url()}
              alt={mainImage}
            />
            <div>
              <h2 className={responsiveTitle2Bold}>{title}</h2>
              {contractor && <p className='contractor'>{contractor}</p>}
              {_rawBody && <BlockContent blocks={_rawBody || []} />}
              {location && <p className='location'><span><FiMapPin size='0.8em' /></span>{location}</p>}
            </div>
          </Fade>
        </Wrapper>
      </RenderInBrowser>

      <RenderInBrowser only safari ie>
        <Wrapper>
          <div>
            <Image
              src={imageUrlFor(buildImageObj(mainImage))
                .width(600)
                .height(350)
                .fit('crop')
                .url()}
              alt={mainImage}
            />
          </div>
          <div>
            <h2 className={responsiveTitle2Bold}>{title}</h2>
            {contractor && <p className='contractor'>{contractor}</p>}
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
            {location && <p className='location'><span><FiMapPin size='0.8em' /></span>{location}</p>}
          </div>
        </Wrapper>
      </RenderInBrowser>
    </>

  )
}

export default Article
