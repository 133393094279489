import React from 'react'
import styled from 'styled-components'
import {responsiveTitle1, responsiveTitle2} from '../components/typography.module.css'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Fade from 'react-reveal/Fade'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 2em;
  padding-bottom: 2em;
  @media (max-width:600px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 0em;
  }
  h1{
    margin-bottom:0;
  }
  `
const WrapperNoImage = styled(Wrapper)`
  grid-template-rows: 1fr ;
  `
const Image = styled.img`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start:1;
  grid-row-end: 3;
  width: 100%;
  @media (max-width:600px) {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start:1;
  grid-row-end: 1;
  }
`
const SecondaryImage = styled(Image)`
  opacity:0;
  :hover{
    opacity:1;
  }
`
function Hero (props) {
  const {title, excerpt, mainImage, secondaryImage} = props
  return (
    <>
      {mainImage && <Wrapper>
        {mainImage && <Image
          src={imageUrlFor(buildImageObj(mainImage))
            .width(1000)
            .height(300)
            .fit('crop')
            .url()
          }
          alt={mainImage}
        />}
        {secondaryImage && <SecondaryImage
          src={imageUrlFor(buildImageObj(secondaryImage))
            .width(1000)
            .height(300)
            .fit('crop')
            .url()}
          alt={secondaryImage}
        />}
        {title && <h1 className={responsiveTitle1}>{title}.</h1>}
        {excerpt && <h2 className={responsiveTitle2}>{excerpt}</h2>}
      </Wrapper>}
      {!mainImage && <WrapperNoImage>
        {title && <h1 className={responsiveTitle1}>{title}.</h1>}
        {excerpt && <h2 className={responsiveTitle2}>{excerpt}</h2>}
      </WrapperNoImage>}

    </>
  )
}

export default Hero
