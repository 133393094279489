import React from 'react'
import styled from 'styled-components'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Fade from 'react-reveal/Fade'
import {responsiveTitle1Bold} from '../components/typography.module.css'

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
  grid-column-gap: 2em;
  align-items:center;
  padding-bottom: 2em;
  @media (max-width:800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width:600px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-row-gap: 0em;
  }
  h1{
    margin-bottom:0;
  }
  `
const Image = styled.img`
width: 100%;
`
function Logo (props) {
  const {mainImage, title, url} = props
  return (
    <>
      <Fade>
        <a href={url}>
          <Image
            src={imageUrlFor(buildImageObj(mainImage))
              .width(300)
              .url()}
            alt={mainImage.alt}
          />
        </a>
      </Fade>
    </>
  )
}
function LogoGrid (props) {
  const {logos} = props
  return (
    <>
      <h2 className={responsiveTitle1Bold}>Clients:</h2>
      <Wrapper>
        {logos.edges.map(logo => (
          <Logo mainImage={logo.node.mainImage} title={logo.node.title} url={logo.node.url} />
        ))}
      </Wrapper>
    </>
  )
}

export default LogoGrid
