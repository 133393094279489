import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Hero from '../components/hero'
import LogoGrid from '../components/logo-grid'
import Layout from '../containers/layout'
import Article from '../components/article'

export const query = graphql`
query ReferencesQuery {
  contractorLogos: allSanityContractorLogos {
    edges {
      node {
        id
        title
        url
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
      }
    }
  }
  site: sanitySiteSettings {
    referencesExcerpt
    referencesImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    referenceProjectOrder {
      title
      location
      contractor
      publishedAt
      _rawBody
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
    }
  }
}
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = data.site
  const contractorLogos = data.contractorLogos
  return (
    <Layout>
      <SEO title='References' description={site.referencesExcerpt} />
      <Container>
        <Hero excerpt={site.referencesExcerpt} title='References' mainImage={site.referencesImage} />
        {
          site.referenceProjectOrder.map(project => (
            <Article key={project.id} _rawBody={project._rawBody} mainImage={project.mainImage} title={project.title} location={project.location} contractor={project.contractor} />
          ))
        }
        <LogoGrid logos={contractorLogos} />
      </Container>
    </Layout>
  )
}

export default IndexPage
